<template>
  <div></div>
</template>

<script>
import Auth0Lock from "auth0-lock";

export default {
  mounted() {
      var options = {
          autoclose : true,
  auth: {
    redirect: false,
    cacheLocation: 'localstorage',
    params: {
    audience: 'https://api.brime.tv/',
    },
  }
};
    var lock = new Auth0Lock(
      "wlcytpe0H57ddqbMcU1UfhR1PLYWylXC",
      "auth.brime.tv",
      options
    );
    lock.on("authenticated", function(authResult) {
    lock.checkSession({}, function (error, authResult) {
      if (error || !authResult) {
        lock.show();
      } else {
        // user has an active session, so we can use the accessToken directly.
        lock.getUserInfo(authResult.accessToken, function (error, profile) {
          console.log(authResult);
        });
      }
    });
    })
    lock.checkSession({}, function (error, authResult) {
      if (error || !authResult) {
        lock.show();
      } else {
        // user has an active session, so we can use the accessToken directly.
        lock.getUserInfo(authResult.accessToken, function (error, profile) {
          console.log(authResult);
        });
      }
    });
  },
};
</script>

<style>
</style>